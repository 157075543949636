<template>
  <div class='serviceFeeList'>
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="orderValue" placeholder="搜索缴费单号" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="barCode" placeholder="搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-date-picker v-model="datetime" type="date" placeholder="选择结算时间" @change="handleSelectTime"
          value-format="yyyy-MM-dd"></el-date-picker>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column show-overflow-tooltip prop="number" label="序号" width='80'>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="feeCode" label="缴费单号"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="createdTime" label="结算时间"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip label="结算周期" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.startDate + '~' + scope.row.endDate }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column min-width="120" show-overflow-tooltip prop="productCode" label="商品编号"></el-table-column> -->
          <el-table-column min-width="120" show-overflow-tooltip prop="barCode" label="商品条形码"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="categoryName" label="商品类目"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="settlementAmount"
            label="结算金额(元)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="feeRate" label="费率(%)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="feeAmount" label="技术服务费(元)"></el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

  </div>
</template>

<script>

export default {
  data() {
    return {
      loading: false,
      orderValue: '', // 缴费单号
      serialIdValue: '', // 商品编号
      barCode: '', // 条形码
      datetime: '',  // 结算日期
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getTechDetailInfoList()
  },
  methods: {
    // 列表
    getTechDetailInfoList() {
      this.loading = true;
      this.$axios.get(this.$api.getTechDetailInfoList, {
        params: {
          bizId: this.$store.state.userInfo.shopId,
          bizType: 1,
          feeCode: this.orderValue || null, //缴费单号
          // productCode: this.serialIdValue || null, // 商品编号
          barCode: this.barCode || null, // 商品编号
          endDate: this.datetime || null, // 结束时间
          startDate: this.datetime || null, // 开始时间
          page: this.currentPage,
          pageSize: this.pageSize,
        },
      }).then((res) => {
        if (res.data.code == 100) {
          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    // 搜索
    onSearch() {
      this.tableData = []
      this.currentPage = 1
      this.getTechDetailInfoList()
    },
    handleSelectTime(value) {
      this.datetime = value;
      this.onSearch();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e;
      this.getTechDetailInfoList();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e;
      this.getTechDetailInfoList();
    },
  },
}
</script>

<style scoped lang='scss'>
.serviceFeeList {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 220px;
    }

    .el-input,
    .el-button {
      margin-left: 20px;
    }
  }
}
</style>
